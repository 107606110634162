import { Routes, Route } from "react-router-dom";
import App from "./App";
import Certificates from "./certificates/Certificates";

function RoutesWrapper() {
  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/certificates/:id" element={<Certificates />} />
    </Routes>
  );
}

export default RoutesWrapper;
