import React from "react";
import { IoMdClose } from "react-icons/io";

function SubScreen({ links, onBack, title }) {
  return (
    <div
      style={{
        maxWidth: 600,
        width: "80vw",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 44,
        }}
      >
        <div style={{ fontSize: 36 }}>{title}</div>
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={onBack}
        >
          <IoMdClose size={38} />
        </div>
      </div>
      <div style={{ fontSize: 18, overflow: "scroll", maxHeight: "80vh" }}>
        {links.map((link, i) => (
          <p style={{ marginBottom: 32 }}>
            {i + 1}.&nbsp;&nbsp;
            <a href={link.link} className="icon">
              {link.label}
            </a>
          </p>
        ))}
      </div>
    </div>
  );
}

export default SubScreen;
