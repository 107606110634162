import logo from "./images/logo.jpeg";
import "./App.css";
import { BsFacebook, BsTwitter, BsYoutube } from "react-icons/bs";

import { AiFillInstagram } from "react-icons/ai";
import SubScreen from "./SubScreen";
import { useState } from "react";

const talks = [
  {
    link: "https://www.youtube.com/watch?v=p1uxZMGyX6E",
    label: "Talk at Outlier 2021",
  },
  {
    link: "https://medium.com/kontinentalist/meet-the-community-ashris-choudhury-india-in-pixels-creator-1df0cd77b956",
    label: "Interview with Mick Yang from Kontinentalist",
  },
  {
    link: "https://www.youtube.com/watch?v=rzHq2QRzn7Y",
    label: "Talk with Vinamre Kasana from Dostcast",
  },
  {
    link: "https://www.youtube.com/watch?v=druad0hV9K4",
    label: "Podcast with Vedant Rusty from Stories with Rusty",
  },
  {
    link: "https://www.youtube.com/watch?v=GcQZT9QpYCQ",
    label: "Podcast with Chaitanya from Pondering Sonder",
  },
];

const news = [
  {
    link: "https://www.hindustantimes.com/lifestyle/art-culture/this-coder-is-painting-detailed-pictures-of-india-using-public-data-101628331309043.html",
    label:
      "Hindustan Times: This coder is painting detailed pictures of India using public data",
  },
  {
    link: "https://www.news18.com/news/buzz/this-coder-is-re-imagining-the-composition-of-india-with-maps-of-visual-data-4081247.html",
    label:
      "News 18: This coder is re-imagining the composition of India with maps of visual data",
  },
  {
    link: "https://pib.gov.in/PressReleaseIframePage.aspx?PRID=1816312",
    label:
      "Prime Minister Office: PM appreciates IIP for conveying the story of UPI and digital payments through data sonification",
  },
  {
    link: "https://www.hindustantimes.com/india-news/modi-lauds-india-in-pixels-for-digital-payments-explainer-sound-of-money-101649844071965.html",
    label:
      "Hindustan Times: Modi lauds India in Pixels for digital payments, explainer sound of money",
  },
  {
    link: "https://www.ndtv.com/india-news/pm-modi-lauds-india-in-pixels-for-explaining-upi-digital-payments-through-data-sonification-2885234",
    label:
      "NDTV: PM lauds India in Pixels for explaining UPI and digital payments through data sonification",
  },
  {
    link: "https://odishatv.in/videos/technology/why-pm-modi-was-all-praise-for-this-iit-graduate-from-odisha-175024",
    label: "OTV: Why PM Modi was all praise for this IIT graduate from Odisha",
  },
  {
    link: "https://zeenews.india.com/india/pm-narendra-modi-hails-india-in-pixels-for-explaining-upi-digital-payments-via-data-sonification-2453433.html",
    label:
      "Zee News: PM Narendra Modi hails India in Pixels for explaining UPI and digital payments via data sonification",
  },
  {
    link: "https://www.aajtak.in/business/news/story/great-indian-spitting-problem-paan-peek-culture-india-chewing-tobacco-gutkha-tuts-1420722-2022-03-02",
    label:
      "Aaj Tak: India In Pixels पान के थूक से भर सकते हैं 211 स्वीमिंग पूल, ये 3 राज्य सबसे आगे!",
  },
  {
    link: "https://www.mid-day.com/sunday-mid-day/article/jhatpat-india-23189924",
    label:
      "Mid Day: A software developer is leveraging his expertise in coding to make tough-to-find and relevant government agency information available to commoners, using attractive maps and cool graphic design  ",
  },
];

const support = [
  {
    link: "https://patreon.com/indiainpixels",
    label: "Support on Patreon",
  },
  {
    link: "upi://pay?pa=iip@upi&pn=INDIA IN PIXELS&cu=INR",
    label: "UPI at iip@upi",
  },
];

//https://www.youtube.com/watch?v=J1ddCeotLcw

function App() {
  const [mode, setMode] = useState("home");

  const reset = () => setMode("home");

  const navigate = (str) => setMode(str);
  return (
    <div className="App">
      <div className="centreContent">
        {mode === "talks" && (
          <SubScreen title="Talks" links={talks} onBack={reset} />
        )}
        {mode === "news" && (
          <SubScreen title="News" links={news} onBack={reset} />
        )}
        {mode === "support" && (
          <SubScreen title="Support" links={support} onBack={reset} />
        )}

        {mode === "home" && (
          <>
            <img style={{ width: 200 }} src={logo} alt="logo" />
            <p
              style={{
                maxWidth: 600,
                padding: "0 24px",
                textAlign: "center",
                lineHeight: 1.67,
                marginBottom: 32,
                marginTop: 0,
                fontSize: 22,
              }}
            >
              Data visualisation about India, one map at a time
            </p>
            <div style={{ display: "flex" }}>
              <a href="https://youtube.com/indiainpixels" className="icon">
                <BsYoutube size={32} />
              </a>
              <a href="https://twitter.com/indiainpixels" className="icon">
                <BsTwitter size={32} />
              </a>
              <a href="https://instagram.com/india.in.pixels" className="icon">
                <AiFillInstagram size={34} />
              </a>
              <a href="https://fb.com/indiainpixels" className="icon">
                <BsFacebook size={32} />
              </a>
            </div>
            <div style={{ marginTop: 32, display: "flex" }}>
              <div>
                <a href="#" className="icon" onClick={() => navigate("talks")}>
                  Talks
                </a>
              </div>
              <div>
                <a href="#" className="icon" onClick={() => navigate("news")}>
                  News
                </a>
              </div>
              <div>
                <a className="icon" href="https://github.com/indiainpixels">
                  Github
                </a>
              </div>
              <div>
                <a
                  className="icon"
                  href="#"
                  onClick={() => navigate("support")}
                >
                  Support
                </a>
              </div>
            </div>
            <div style={{ marginTop: 32 }}>
              <div>
                Created by{" "}
                <a className="icon" href="https://iashris.com">
                  Ashris
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default App;
