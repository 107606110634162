import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import "./Certificates.css";
import names from "./names.json";
const toWord = {
  2: "two",
  3: "three",
  4: "four",
  5: "five",
};

export default function Certificates() {
  useEffect(() => {
    document.body.style.backgroundColor = "#373dc2";
  }, []);
  const { id } = useParams();
  const student = names.find((student) => student.slug === id);
  if (!student) return <div className="certificates">No student found</div>;

  return (
    <div className="certificates">
      <div className="title underlined underlined--gradient">
        Certificate of {student.num === 5 ? "Distinction" : "Completion"}
      </div>
      <p className="text">
        This is to certify that <b>{student.name}</b> has successfully completed
        the course <b>Learn Data Visualization With India in Pixels</b> on 7th
        August 2022{student.num === 5 ? " with distinction" : ""}. Over the
        course of six weeks and {toWord[student.num]} assignments,{" "}
        {student.first} has learned to create data visualizations using Python
        and Javascript. They learned how to collect, clean, and analyze data and
        narrate insights in a visual manner. The course was taught by{" "}
        <b>Ashris Choudhury</b>, creator of the data visualization platform{" "}
        <b>India in Pixels.</b>
      </p>
      <p className="text">
        They are hereby deemed capable and skilled in creating visual stories
        with data.
      </p>
      <h1>Certified by India in Pixels</h1>
      <em className="link">indiainpixels.xyz/certificates/{id}</em>
    </div>
  );
}
